html {
  font-size: 62.5%;
}

body, #root {
  font-family: 'Gotham SSm', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  color: inherit;
  font-family: inherit;
}
